//variablen muessen pro instanz angepasst werden
module.exports = {
    baseUrl: 'https://mantel-api.mobile.documedias.systems/v2/checkall/',
    baseUrlDownload: 'https://mantel-api.mobile.documedias.systems/v2/downloadall/',
    baseUrlCDN: "https://documedias-188ab.kxcdn.com/",
    cdnInitStamp: "2022-04-13T14:12:33.808Z",
    conference: '1341',
    short_name: 'cimt2022',
    full_name: 'CIMT 2022',
    api_version: 2, //change this to api version 2 for new conferences!!!
    conf_date : '10-12 May',
    api_url : 'https://coredev.api.documedias.systems/api/',
    appointment_api_url : 'https://dev.appointment.documedias.systems/api/',
    auth_url : 'https://auth.documedias.com/',
    auth_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxMzM0LCJjb25ncmVzc0lkIjoxMzQxLCJkYXRlIjoiMjAyMi0wNS0wNFQwODoyOTo0MC43MDFaIiwiaWF0IjoxNjUxNjUyOTgwfQ.fGsFg0j4q8a1tfIFDE92BTgLMCWYsIl911omo0kI5PU',
    auth_dev_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxMzA0LCJjb25ncmVzc0lkIjoxMzQxLCJkYXRlIjoiMjAyMi0wNC0xNFQwODo1NDoyOC40MTlaIiwiaWF0IjoxNjQ5OTI2NDY4fQ.QWR5JM7ERD3-UO5iiTN4wZiUBBFNRmbDs0dyQGTvKAM',
    vue_app_access_key : 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjo5LCJjb25ncmVzc0lkIjoxLCJkYXRlIjoiMjAxOC0xMC0wMVQwOTo0Njo0MC42MDlaIiwiaWF0IjoxNTM4Mzg3MjAwfQ.1Z8vRJF2gZtCuLDEWxQhNyvlYHS6YZsdJxvoYGfyo112afY8CofuEBP4_jvIPXhc',
    chat_url: 'https://cimt2022.chat.documedias.systems/api/',
    is_app: false //unbedingt anpassen bevor App deploy
}
