<template>
  <ion-page>
    <!--simulate iontoolbar margin/padding stuff-->
    <div class="content-gap"></div><!--:style="{height: isIos ? '100px' : '50px'}"-->

    <ion-progress-bar  type="indeterminate" v-if="syncActive && currentProgress == 0"></ion-progress-bar>
    <ion-progress-bar color="primary" v-if="syncActive && currentProgress > 0"
                      :value="currentProgress"></ion-progress-bar>

    <small class="ion-padding checking-data" v-if="syncActive && downloadSize === 0">Checking data</small>
    <small class="ion-padding checking-data" v-if="syncActive && downloadSize && downloadLabel">{{ downloadLabelText }}
      {{ downloadSize }} {{ downloadLabel }} - {{ (currentProgress * 100).toFixed(2) }}%</small>

    <banner direction="horizontal" position="top"></banner>

    <slot></slot>
    <banner  v-if="this.$route.name!='Videoroom' && this.$route.name!='Meetings Manager'" direction="vertical" position="bottom"></banner>
    <ion-footer v-if="this.$route.name!='Videoroom'">
      <ion-toolbar class="docu-footer">

        <div class="footer-container">
          <a class="button button-clear button-positive footer-link" style=""
             href="https://www.abstractserver.com/datenschutz/imprint.html#eng" target="_blank">Imprint</a>
          <a class="button button-clear button-positive footer-link"
            href="https://www.abstractserver.com/datenschutz/data_security_agreement.html#eng" target="_blank">Privacy</a>
          <a class="button button-clear button-positive footer-link"
             href="mailto:office@cimt.eu">Support</a>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonFooter,
  IonToolbar,
  IonProgressBar,
} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapState, mapGetters} from "vuex";
import {isPlatform, modalController} from '@ionic/vue';
import PdfModal from "@/views/Industry/PdfModal";

const config = require('@/config')
import {addIcons} from "ionicons";
import {personCircleOutline, logoRss} from "ionicons/icons";
import {logOutOutline} from "ionicons/icons";
import {logInOutline} from "ionicons/icons";

addIcons({"person-circle-outline": personCircleOutline});
addIcons({"log-out-outline": logOutOutline})
addIcons({"log-in-outline": logInOutline})
addIcons({"logo-rss": logoRss})

export default defineComponent({
  name: "App",
  components: {
    IonPage,
    IonFooter,
    IonToolbar,
    IonProgressBar,
  },
  data() {
    return {

      isDesktop: null,
      isIos:null,
      title: config.full_name,
      
    }
  },
  mounted() {
  },
  methods: {


    async handleFile() {
      let link = "https://efic2022.abstractserver.com/documents/EFIC2022_Privacy%20Policy.pdf"
      let viewerLink =  link
      console.log(viewerLink)

      this.modal = await modalController
          .create({
            component: PdfModal,
            cssClass: 'pdf-modal',
            componentProps: {
              title: "Privacy policy",
              link: viewerLink
            },
          })
      return this.modal.present();
    },


  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress','downloadLabelText'])
  },
  async created() {
    this.isDesktop = isPlatform('desktop');
    this.isIos = isPlatform('ios')
  },

   watch: {
    '$route': {
      immediate: true,
      async handler() {

       
      }
    }
  }





});
</script>
<style lang="scss">

ion-content {
  //--padding-start: env(safe-area-inset-left); /* Apply safe area */
  //--padding-end: env(safe-area-inset-right);
}

.docu-footer {
  background: white !important;
  border-top: 1px solid lightgrey;

  .footer-container {
    width: 100%;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center; //center
    padding-right: 10px;



    .footer-link {
      color: #274564 !important;
      margin: 1px 5px 2px 5px;
      font-weight: 400;
      font-size: 0.9rem;
      text-decoration: none;
    }
  }

}

 ion-progress-bar {
    --background: #274564 ;
    --buffer-background: #274564 ;
    --progress-background: #F49301;
  }



</style>
