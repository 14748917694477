<template>
    <div class="header mobile-deactivated ">
      <div class="header-content">
        <img src="@/assets/banner.png"/>
      </div>
    </div>

</template>

<script>
import {
} from "@ionic/vue";
import { isPlatform } from '@ionic/vue';

export default {
  components: {
  },
  data() {
    return {
      isDesktop: null,
    }
  },
  computed: {
  },
  methods: {
 
  
  },
  async created() {
    this.isDesktop= isPlatform('desktop');
  }
};
</script>
<style lang="scss">



</style>